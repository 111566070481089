<template>
  <a
    href="/license"
    class="license-wrapper"
    v-if="getExpireDay !== null && getExpireDay < 5"
  >
    <div
      class="license-container"
      v-if="getExpireDay !== null && getExpireDay > 0"
    >
      <i class="fas fa-exclamation-circle"></i>Lisans sürenizin bitmesine
      {{ getExpireDay !== null && getExpireDay }} gün kaldı!
      <button class="renew-license">Yenile</button>
    </div>
    <div
      class="license-container"
      v-if="getExpireDay !== null && getExpireDay <= 0"
    >
      <i class="fas fa-exclamation-circle"></i>Lisans süreniz bitti!
      <button class="renew-license">Yenile</button>
    </div>
  </a>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["session"]),
    getExpireDay() {
      if (
        this.session &&
        this.session.config &&
        this.session.config["app.expired_at"]
      ) {
        const nowDate = new Date(Date.now());

        const expireDate = new Date(this.session.config["app.expired_at"]);

        const diffTime = expireDate - nowDate;

        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss">
.license-wrapper {
  height: max-content;
  display: flex;
  width: 100%;
  min-height: 36px;
  background-color: #3f51b5;
  z-index: 9999;
  text-decoration: none !important;
}
.license-container {
  background-color: rgb(239 68 68);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  position: relative;
  z-index: 9999;
  left: 0;
  right: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.license-container:hover {
  background-color: rgb(239 68 35);
  cursor: pointer;
}
.license-container > .fas {
  margin-right: 6px;
}
.renew-license {
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  background-color: #ffffff;
  color: #ef4444;
  line-height: 20px;
  margin-left: 16px;
}
.renew-license:focus {
  outline: none;
}
</style>
